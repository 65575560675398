/* eslint-disable camelcase */
import i18next from 'i18next';
import { CountriesEnum } from '@/enums/countries';
import TranslationsEN from './translations/en/translation';
import TranslationsES from './translations/es/translation';
import TranslationsNL_NL from './translations/nl-nl/translation';
import TranslationsNL_BE from './translations/nl-be/translation';
import TranslationsDA from './translations/da/translation';
import TranslationsDE from './translations/de/translation';
import TranslationsFR from './translations/fr/translation';
import TranslationsIT from './translations/it/translation';
import TranslationsNO from './translations/no/translation';
import TranslationsPT from './translations/pt/translation';
import TranslationsEL from './translations/el/translation';
import TranslationsSV from './translations/sv/translation';

// i18next expects resources in this format: nl-NL
// https://www.i18next.com/principles/translation-resolution#languages
i18next.init({
	resources: {
		en: TranslationsEN,
		es: TranslationsES,
		'nl-NL': TranslationsNL_NL,
		da: TranslationsDA,
		de: TranslationsDE,
		fr: TranslationsFR,
		it: TranslationsIT,
		no: TranslationsNO,
		pt: TranslationsPT,
		el: TranslationsEL,
		sv: TranslationsSV,
		'nl-BE': TranslationsNL_BE,
	},
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
	react: {
		useSuspense: false,
	},
});

i18next.languages = CountriesEnum.map(country => country.locale);

export default i18next;
