// gatsby-ssr.js
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { PageContextProvider } from './src/context/page-context';
import i18n from './i18next';

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;

/**
 * Wrap all pages with a Page context provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => <PageContextProvider {...props}>{element}</PageContextProvider>;
