/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';
import './static/plyr/plyr.css';
import './static/react-slick/slick.css';

import '@fontsource/roboto-slab/700.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

export { wrapPageElement, wrapRootElement } from './gatsby-ssr';

export const onClientEntry = async () => {
	if (typeof IntersectionObserver === 'undefined') {
		await import('intersection-observer');
	}
};
