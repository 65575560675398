import React from 'react';
import { useTranslation } from 'react-i18next';

const PageContext = React.createContext({});

export function PageContextProvider({ pageContext, children }) {
	const { i18n } = useTranslation();

	if (pageContext.locale !== i18n.language) {
		i18n.changeLanguage(pageContext.locale);
	}

	return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>;
}

export const usePageContext = () => React.useContext(PageContext);
